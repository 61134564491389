import React from 'react'

import ErrorPage from '../../../system/ErrorPage'

const InfoSessions = () => {
  // page no longer used, but there's a dangling reference in Contentful somewhere, so we still need to handle it
  return <ErrorPage />
}

export default InfoSessions
